import { mockListingDetails, mockListingDetails1001, mockListingDetailsWithRelatedListingsData, mockUserListings } from '@utils/mockData';
import Axios from 'axios'
import {renderAllListingsUrl, renderCreateListingUrl, renderDeleteListingUrl, renderGetListingExtensionPaymentUrl, renderGetListingPaymentUrl, renderGetUserListingsUrl, renderCheckEmailUsers, renderListingDetailsUrl, renderListingEnquiryUrl, renderRelatedListingsUrl, renderReportListingUrl, renderSendListingEnquiry, renderUpdateListingUrl, renderListingDetailsByUserUrl} from '../utils/MarketplaceApiUrls';


const getListingDetails = async (apiUrl: string, listingId: number) =>{
    const url = renderListingDetailsUrl(apiUrl,listingId);

    const result = await Axios.get(url);
    return result.data.Data;
}

const getListingDetailsByUser = async (apiUrl: string, listingId: number, jwtToken: string) =>{
    const url = renderListingDetailsByUserUrl(apiUrl,listingId);
     const config = {
         headers: { Authorization: `Bearer ${jwtToken}` }
     };

    const result = await Axios.get(url, config);
    return result.data.Data;
}

const getAllListings = async (apiUrl: string) =>{
    const url = renderAllListingsUrl(apiUrl);
    return Axios.get(url);
}

const getRelatedListings = async (apiUrl: string, listingId: number, limit: number) =>{
    const url = renderRelatedListingsUrl(apiUrl,listingId, limit);
    const result = await Axios.get(url);
    return result.data.Data;
}

const createListing = async (apiUrl: string, jwtToken: string, model: any) => {
    const url = renderCreateListingUrl(apiUrl);
    const config = {
        headers: { Authorization: `Bearer ${jwtToken}` }
    };

    const result = await Axios.post(url, model, config);
    // console.log(result);
    return result.data.Data;
}

const getListingPaymentUrl = async (apiUrl: string, jwtToken: string, listingId: number, data: any) => {
    const url = renderGetListingPaymentUrl(apiUrl, listingId);
    const config = {
        headers: { Authorization: `Bearer ${jwtToken}` }
    };

    const result = await Axios.post(url, data, config);

    return result.data.Data;
}

const getListingExtensionPaymentUrl = async (apiUrl: string, jwtToken: string, listingId: number, data: any) => {
    const url = renderGetListingExtensionPaymentUrl(apiUrl, listingId);
    const config = {
        headers: { Authorization: `Bearer ${jwtToken}` }
    };

    const result = await Axios.post(url, data, config);

    return result.data.Data;
}

const editListing = async (apiUrl: string, jwtToken: string, model: any, listingId: number) => {
    const url = renderUpdateListingUrl(apiUrl, listingId);
    const config = {
        headers: { Authorization: `Bearer ${jwtToken}` }
    };

    const result = await Axios.post(url, model, config);

    return result.data.Data;
}

const mockGetListingDetailsWithRelatedListings = async (apiUrl: string, listingId: number) =>{
    if(listingId = 10001){
        return Promise.resolve(mockListingDetailsWithRelatedListingsData)
    }else{
        return  Promise.resolve(mockListingDetailsWithRelatedListingsData);
    }
}


const mockGetListingDetails = async (apiUrl: string, listingId: number) =>{    
    if(listingId === 10001){
        return Promise.resolve(mockListingDetails1001)
    }else{
        return  Promise.resolve(mockListingDetails);
    }
}

const listingEnquiry = async (apiUrl: string, listingId: number, name: string, email: string, message: string, kiwiVip: boolean) =>{
    const url = renderListingEnquiryUrl(apiUrl, listingId);
    const postData = {name: name, email: email, message: message, kiwiVip: kiwiVip};
    const result = await Axios.post(url, postData);    
    const data = result.data;
    if(data.Success){
        return data.Data;
    }
}

const mockListingEnquiry = async (apiUrl: string, listingId: number, name: string, email: string, message: string, kiwiVip: boolean) =>
    Promise.resolve({success: true})

const sendListingEnquiry = async(apiUrl: string, listingId: number, name: string, email: string, message: string) => {
    const url = renderSendListingEnquiry(apiUrl);

    return await Axios.post(url,
        {
            ListingId: listingId,
            Name: name,
            Message: message,
            EmailFrom: email
        }
    );

}

const reportListing = async(apiUrl: string, listingId: number, name: string, email: string, reason: string, message: string) => {
    const url = renderReportListingUrl(apiUrl);

    return await Axios.post(url,
        {
            ListingId: listingId,
            Name: name,
            Message: message,
            Email: email,
            Reason: reason
        }
    );

}

const deleteListing = async(apiUrl: string, jwtToken: string, listingId: number) =>{
    const url = renderDeleteListingUrl(apiUrl, listingId);

    const config = {
        headers: { Authorization: `Bearer ${jwtToken}` }
    };

    return await Axios.delete(url, config);
}    
const mockDeleteListing = async(apiUrl: string, listingId: number) =>
    Promise.resolve(true);

const getUserListings = async(apiUrl: string, jwtToken: string) =>{
    const url = renderGetUserListingsUrl(apiUrl);
    const config = {
        headers: { Authorization: `Bearer ${jwtToken}` }
    };
    const result = await Axios.get(url, config);
    return result.data.Data;
}
const mockGetUserListings = async(apiUrl: string, sub: string) =>{
    return Promise.resolve(mockUserListings);
}
const checkEmailUsers = async(apiUrl: string, email: string) =>{
    const url = renderCheckEmailUsers(apiUrl);
    
    const result = await Axios.post(url, {Email: email});
    return result.data;
}

export const MarketplaceListingService = {
    getListingExtensionPaymentUrl: getListingExtensionPaymentUrl,
    getListingPaymentUrl: getListingPaymentUrl,
    getListingDetails: getListingDetails,
    getRelatedListings: getRelatedListings,
    listingEnquiry: listingEnquiry,
    getAllListings: getAllListings,
    createListing: createListing,
    editListing: editListing,
    getUserListings: getUserListings,
    deleteListing: deleteListing,
    sendListingEnquiry: sendListingEnquiry,
    reportListing: reportListing,
    checkEmailUsers: checkEmailUsers,
    getListingDetailsByUser: getListingDetailsByUser
    //TODO: remove after api endpoint ready
    // mockGetListingDetails: mockGetListingDetails,
    // mockGetListingDetailsWithRelatedListings: mockGetListingDetailsWithRelatedListings,
    // mockListingEnquiry: mockListingEnquiry,
    // mockGetUserListings: mockGetUserListings,
    // mockDeleteListing: mockDeleteListing
}