
export const renderListingDetailsUrl = (apiUrlBase:string, listingId:number) =>{
    return `${apiUrlBase}/marketplace/listing/${listingId}`;
}
export const renderListingDetailsByUserUrl = (apiUrlBase:string, listingId:number) =>{
    return `${apiUrlBase}/marketplace/listing-by-user/${listingId}`;
}
export const renderAllListingsUrl = (apiUrlBase:string) =>{
    return `${apiUrlBase}/marketplace/listings/all`;
}
export const renderRelatedListingsUrl = (apiUrlBase:string, listingId:number, limit: number) =>{
    return `${apiUrlBase}/marketplace/listing/${listingId}/related?limit=${limit}`;
}
export const renderCreateListingUrl = (apiUrlBase:string) =>{
    return `${apiUrlBase}/marketplace/listing`;
}
export const renderGetListingPaymentUrl = (apiUrlBase:string, listingId: number) =>{
    return `${apiUrlBase}/marketplace/payment/${listingId}`;
}
export const renderGetListingExtensionPaymentUrl = (apiUrlBase:string, listingId: number) =>{
    return `${apiUrlBase}/marketplace/payment/${listingId}/extension`;
}
export const renderUpdateListingUrl = (apiUrlBase:string, listingId: number) =>{
    return `${apiUrlBase}/marketplace/listing/${listingId}`;
}
export const renderListingEnquiryUrl = (apiUrlBase:string, listingId:number) =>{
    return `${apiUrlBase}/listingEnquiry/${listingId}`;
}
export const renderDeleteListingUrl = (apiUrlBase:string, listingId:number) =>{
    return `${apiUrlBase}/marketplace/listing/${listingId}`;
}
export const renderSendListingEnquiry = (apiUrlBase:string) =>{
    return `${apiUrlBase}/marketplace/listing/enquiry`;
}
export const renderReportListingUrl = (apiUrlBase:string) =>{
    return `${apiUrlBase}/marketplace/listing/report`;
}
export const renderGetUserListingsUrl = (apiUrlBase:string) =>{
    return `${apiUrlBase}/marketplace/listings/user`;
}
export const renderCheckEmailUsers = (apiUrlBase:string) =>{
    return `${apiUrlBase}/marketplace/listings/emailusers`;
}