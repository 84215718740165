// import { ListingStatus, ListingType } from "./ListingTypes"

import { ListingDetailsType } from "./ListingTypes"

export type StandardListingType = {
  combination: string
  askingPrice: number
  listingId: number
  plateDesign: string
  startDate: Date
}

export const mockFeaturedListings = [
  {
    combination: "ABC004",
    askingPrice: 350,
    listingId: 10001,
    plateDesign: "Kiwi Color Plate",
    startDate: new Date(2021, 10, 12),
  },
  {
    combination: "ABC001",
    askingPrice: 340,
    listingId: 10002,
    plateDesign: "Kiwi Color Plate",
    startDate: new Date(2021, 10, 12),
  },
  {
    combination: "ABC00E",
    askingPrice: 670,
    listingId: 10034,
    plateDesign: "Kiwi Color Plate",
    startDate: new Date(2021, 10, 12),
  },
  {
    combination: "AB1",
    askingPrice: 840,
    listingId: 10012,
    plateDesign: "Kiwi Color Plate",
    startDate: new Date(2021, 10, 12),
  },
]

export const mockListingDetails = {
  combination: "STEVEN",
  askingPrice: 350,
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  plateDesign: "other",
  isManufactured: false,
  condition: "good",
  imageUrls: [
    {
      url: "https://api-uat.kiwiplates.nz/api//image/68/ABC123/4/4/max/750",
    },
    {
      url:
        "https://preview-assets-au-01.kc-usercontent.com/a2aeafcd-75c2-0255-66a2-45429a9cdff4/8510cf89-b3bf-4ea3-9028-e32e172058cb/1.jpg",
    },
  ],
}

export const mockListingDetails1001 = {
  combination: "PETER",
  askingPrice: 2300,
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  plateDesign: "american",
  isManufactured: true,
  condition: "good",
  imageUrls: [
    {
      url: "https://api-uat.kiwiplates.nz/api//image/68/ABC123/4/4/max/750",
    },
    {
      url:
        "https://preview-assets-au-01.kc-usercontent.com/a2aeafcd-75c2-0255-66a2-45429a9cdff4/8510cf89-b3bf-4ea3-9028-e32e172058cb/1.jpg",
    },
  ],
}

export const mockRelatedListings = [
  {
    combination: "BEACH",
    askingPrice: 350,
    listingId: 10001,
    plateDesign: "Kiwi Color Plate",
    startDate: new Date(2021, 10, 12),
  },
  {
    combination: "RUGBY",
    askingPrice: 850,
    listingId: 10002,
    plateDesign: "Team Plate",
    startDate: new Date(2021, 11, 12),
  },
  {
    combination: "BITCN",
    askingPrice: 2000,
    listingId: 10003,
    plateDesign: "Standard",
    startDate: new Date(2021, 5, 12),
  },
]
export const mockListingDetailsWithRelatedListingsData = {
  listing: {
    combination: "SUMMER",
    askingPrice: 350,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    plateDesign: "European Plate",
    manufactured: true,
    condition: "New",
    imageUrls: [
      {
        url: "https://api-uat.kiwiplates.nz/api//image/68/ABC123/4/4/max/750",
      },
      {
        url:
          "https://preview-assets-au-01.kc-usercontent.com/a2aeafcd-75c2-0255-66a2-45429a9cdff4/8510cf89-b3bf-4ea3-9028-e32e172058cb/1.jpg",
      },
      {
        url:
          "https://preview-assets-au-01.kc-usercontent.com/a2aeafcd-75c2-0255-66a2-45429a9cdff4/8510cf89-b3bf-4ea3-9028-e32e172058cb/1.jpg",
      },
      {
        url:
          "https://preview-assets-au-01.kc-usercontent.com/a2aeafcd-75c2-0255-66a2-45429a9cdff4/8510cf89-b3bf-4ea3-9028-e32e172058cb/1.jpg",
      },
      {
        url:
          "https://preview-assets-au-01.kc-usercontent.com/a2aeafcd-75c2-0255-66a2-45429a9cdff4/8510cf89-b3bf-4ea3-9028-e32e172058cb/1.jpg",
      },
      {
        url:
          "https://preview-assets-au-01.kc-usercontent.com/a2aeafcd-75c2-0255-66a2-45429a9cdff4/8510cf89-b3bf-4ea3-9028-e32e172058cb/1.jpg",
      },
      {
        url:
          "https://preview-assets-au-01.kc-usercontent.com/a2aeafcd-75c2-0255-66a2-45429a9cdff4/8510cf89-b3bf-4ea3-9028-e32e172058cb/1.jpg",
      },
      {
        url:
          "https://preview-assets-au-01.kc-usercontent.com/a2aeafcd-75c2-0255-66a2-45429a9cdff4/8510cf89-b3bf-4ea3-9028-e32e172058cb/1.jpg",
      },
      {
        url:
          "https://preview-assets-au-01.kc-usercontent.com/a2aeafcd-75c2-0255-66a2-45429a9cdff4/8510cf89-b3bf-4ea3-9028-e32e172058cb/1.jpg",
      },
    ],
  },
  relatedListings: mockRelatedListings,
}

export const mockStandardListings = [
  {
    combination: "BEACH",
    askingPrice: 350,
    listingId: 10001,
    plateDesign: "Kiwi Color Plate",
    startDate: new Date(2021, 10, 12),
  },
  {
    combination: "ABC123",
    askingPrice: 350,
    listingId: 10001,
    plateDesign: "Kiwi Color Plate",
    startDate: new Date(2021, 10, 12),
  },
  {
    combination: "ABC001",
    askingPrice: 350,
    listingId: 10001,
    plateDesign: "Kiwi Color Plate",
    startDate: new Date(2021, 10, 12),
  },
  {
    combination: "ABC002",
    askingPrice: 350,
    listingId: 10001,
    plateDesign: "Kiwi Color Plate",
    startDate: new Date(2021, 10, 12),
  },
  {
    combination: "RUGBY",
    askingPrice: 850,
    listingId: 10002,
    plateDesign: "Team Plate",
    startDate: new Date(2021, 11, 12),
  },
  {
    combination: "BITCN",
    askingPrice: 2000,
    listingId: 10003,
    plateDesign: "Standard",
    startDate: new Date(2021, 5, 12),
  },
  {
    combination: "BIT001",
    askingPrice: 2000,
    listingId: 10003,
    plateDesign: "Standard",
    startDate: new Date(2021, 6, 12),
  },
  {
    combination: "BIT002",
    askingPrice: 2000,
    listingId: 10003,
    plateDesign: "Standard",
    startDate: new Date(2021, 5, 12),
  },
  {
    combination: "BIT003",
    askingPrice: 2000,
    listingId: 10003,
    plateDesign: "Standard",
    startDate: new Date(2021, 5, 13),
  },
  {
    combination: "BIT004",
    askingPrice: 2000,
    listingId: 10003,
    plateDesign: "Standard",
    startDate: new Date(2021, 5, 14),
  },
  {
    combination: "BIT005",
    askingPrice: 2000,
    listingId: 10003,
    plateDesign: "Standard",
    startDate: new Date(2021, 5, 15),
  },
  {
    combination: "BIT006",
    askingPrice: 2000,
    listingId: 10003,
    plateDesign: "Standard",
    startDate: new Date(2021, 5, 16),
  },
  {
    combination: "BIT007",
    askingPrice: 2000,
    listingId: 10003,
    plateDesign: "Standard",
    startDate: new Date(2021, 5, 17),
  },
  {
    combination: "BIT016",
    askingPrice: 2000,
    listingId: 10003,
    plateDesign: "Standard",
    startDate: new Date(2021, 5, 26),
  },
  {
    combination: "BITCN",
    askingPrice: 2000,
    listingId: 10003,
    plateDesign: "Standard",
    startDate: new Date(2021, 5, 27),
  },
  {
    combination: "BIT017",
    askingPrice: 2000,
    listingId: 10003,
    plateDesign: "Standard",
    startDate: new Date(2021, 5, 28),
  },
  {
    combination: "BIT018",
    askingPrice: 2000,
    listingId: 10003,
    plateDesign: "Standard",
    startDate: new Date(2021, 5, 29),
  },
  {
    combination: "BIT008",
    askingPrice: 2000,
    listingId: 10003,
    plateDesign: "Standard",
    startDate: new Date(2021, 5, 18),
  },
  {
    combination: "BIT009",
    askingPrice: 2000,
    listingId: 10003,
    plateDesign: "Standard",
    startDate: new Date(2021, 5, 19),
  },
  {
    combination: "BIT010",
    askingPrice: 2000,
    listingId: 10003,
    plateDesign: "Standard",
    startDate: new Date(2021, 5, 20),
  },
  {
    combination: "BIT011",
    askingPrice: 2000,
    listingId: 10003,
    plateDesign: "Standard",
    startDate: new Date(2021, 5, 21),
  },
  {
    combination: "BIT012",
    askingPrice: 2000,
    listingId: 10003,
    plateDesign: "Standard",
    startDate: new Date(2021, 5, 22),
  },
  {
    combination: "BIT013",
    askingPrice: 2000,
    listingId: 10003,
    plateDesign: "Standard",
    startDate: new Date(2021, 5, 23),
  },
  {
    combination: "BIT014",
    askingPrice: 2000,
    listingId: 10003,
    plateDesign: "Standard",
    startDate: new Date(2021, 5, 24),
  },
  {
    combination: "BIT015",
    askingPrice: 2000,
    listingId: 10003,
    plateDesign: "Standard",
    startDate: new Date(2021, 5, 25),
  },
]

export const mockUserListings: ListingDetailsType[] = [
  {
    listingId: 100,
    combination: "STEVNE",
    price: 1200,
    description: "this is a cool plate",
    plateDesignName: "other",
    isManufactured: true,
    condition: "good",
    listingType: "standard",
    approved: false,
    paymentStatus: 1,
    listingStatus: "Active",
    isFeatured: true,
    listingOwnerId: 'asdfkjaskdfjkadf'
  },
  {
    listingId: 101,
    combination: "STEVN",
    price: 1200,
    description: "this is a cool plate",
    plateDesignName: "other",
    isManufactured: true,
    condition: "good",
    listingType: "standard",
    approved: false,
    paymentStatus: 1,
    listingStatus: "Active",
    isFeatured: true,
    listingOwnerId: 'asdfkjaskdfjkadf'
  },
]
